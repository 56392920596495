// /**
//  * Copyright © Magento, Inc. All rights reserved.
//  * See COPYING.txt for license details.
//  */

//
//  Responsive variables
//  _____________________________________________

$use-flex: true; // Use flexbox [true|false]
$responsive: true; // Theme is responsive [true|false]$

$baseDir: "../"; // Base directory path

$screen__xxs: 320px;
$screen__xs: 480px;
$screen__s: 640px;
$screen__m: 768px;
$screen__l: 1024px;
$screen__xl: 1440px;
$text__color: #696969;
$color-white: #fff;
$color-black: #000;
$color-darkgray: #292929;
$color-wildsand : #f7f7f7;
$color-silver: #c8c8c8;
$color-dovegray: #696969;
$color-gray: #8a8a8a;
$color-dustygray: #949494;
$color-red: #d51224;
$color-mineshaft: #292929;
$color-alto: #ddd;
$color-flint: #6d6964;
$color-cloudy: #b2aca8;
$color-ash: #cac5bc;
$color-santasgray: #a2a6b8;
$color-bokara-grey: #272a2e;
$color-nobel: #b5b5b5;
$color-alabaster: #fcfcfc;
$color-gallery: #f0f0f0;
$color-scorpion: #5e5e5e;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px;
$screen-xs:                  $screen__s;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm:                  $screen__m;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md:                  $screen__l;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg:                  $screen__xl;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

// text color classes
.text-color {
    &-darkgray {
        color: $color-dovegray;
    }
    &-lightgray {
        color: $color-santasgray;
    }
}
