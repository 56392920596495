.car-availability {
    background-color: $color-mineshaft;
    width: 100%;

    &__overview {
        margin: 0 0 20px 20px !important;
        padding-left: 0;
    }

    p {
        padding: 15px;
        color: $color-white;
        margin-bottom: 0;
    }
}