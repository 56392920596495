.shadow-container {
    background: #F7F6F7;
    box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.15);
    padding: 10px;
}

.essentials-wrapper {
    padding-right: 0 !important;
}

.essentials-details-wrapper {
    margin: 50px 0;
}

.highlights-wrapper {
    .highlights {
        border-bottom: 1px solid $color-dovegray;
        margin: 50px 0;

        &--headline {
            display: flex;
            flex-direction: row;
            align-items: center;

            h2 {
                font-size: 16px;
                font-weight: normal;
                padding-right: 20px;
                display: inline-block;
                margin: 0;

                @media (min-width: $screen__m) {
                    padding-right: 35px;
                }
            }
        }

        &--border-top {
            flex: 1;
            width: auto;
            height: 1px;
            border-top: 1px solid $color-dovegray;
        }

        &--container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 42px;
            padding-bottom: 50px;

            @media (min-width: $screen__m) {
                flex-direction: row;
            }
        }

        &--item {
            position: relative;
            text-align: center;
            padding: 25px 5px 0 5px;

            @media (min-width: $screen__m) {
                width: 25%;
            }

            @media (max-width: $screen__m) {
                margin-bottom: 50px;
            }

            &:last-child {
                @media (max-width: $screen__m) {
                    margin-bottom: 0;
                }
            }
        }

        &--icon {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            -webkit-mask-image: url(../../../images/icons/check.svg);
            mask-image: url(../../../images/icons/check.svg);
            background-color: $color-dovegray;
            background-repeat: no-repeat;
            background-position: top center;
            width: 25px;
            height: 25px;
        }
    }
}

.additional-attributes-wrapper {
    li {
        .label {
            display: inline-block;
            width: 50%;

            @media (min-width: $screen__m) {
                width: 35%;
            }
        }
    }
}

.product-main-view {
    display: flex;
    flex-direction: row;
    //width: 100%;
    padding-bottom: 40px;

    @media (max-width: $screen__l) {
        flex-direction: column;
    }

    .left-column,
    .right-column {
        display: flex;
        flex-direction: column;
        padding: 0 !important;

        @media (max-width: $screen__l) {
            display: contents;
        }
    }

    .right-column {
        @media (min-width: $screen__l) {
            padding-left: 14px !important;
        }
    }
}

.left-column {

    &.col-md-5 {
        padding: 0;
    }

    .media-gallery-wrapper.shadow-container {
        flex: 0 0 auto;
        padding: 9px;

        @media (max-width: $screen__l) {
            order: 1;
        }
    }

    .icon-set-wrapper {
        flex: 1 0 auto;

        @media (min-width: $screen__l) {
            margin-top: 14px;
        }

        @media (max-width: $screen__l) {
            order: 3;
        }

        .icon-set-list {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: repeat(auto-fit, minmax(calc(100% / 6), 1fr));
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                display: inline-block;
                width: auto;
                background-repeat: no-repeat;
                background-position: top center;
                background-size: 30px 30px;
                padding: 32px 5px 0 5px;
                margin-top: 15px;
                font-size: 7px;
                text-align: center;

                @media (min-width: $screen__m) {
                    font-size: 10px;
                }

                &.hyphenable {
                    hyphens: auto;
                }

                &.getriebe {
                    background-image: url("../../../images/icons/icon_dtl_getriebe.svg");
                }

                &.kraftstoffart {
                    background-image: url("../../../images/icons/icon_dtl_kraftstoffart.svg");
                }

                &.ez {
                    background-image: url("../../../images/icons/icon_dtl_ez.svg");
                }

                &.power {
                    background-image: url("../../../images/icons/icon-power.svg");
                }

                &.farbe {
                    background-image: url("../../../images/icons/icon_dtl_farbe.svg");
                }

                &.kilometerstand {
                    background-image: url("../../../images/icons/icon_dtl_kilometerstand.svg");
                }

                &.betten {
                    background-image: url("../../../images/icons/icon_dtl_betten.svg");
                }

                &.hoehe {
                    background-image: url("../../../images/icons/icon_dtl_hoehe.svg");
                }

                &.laenge {
                    background-image: url("../../../images/icons/icon_dtl_laenge.svg");
                }

                &.breite {
                    background-image: url("../../../images/icons/icon_dtl_breite.svg");
                }

                &.sitze {
                    background-image: url("../../../images/icons/icon_dtl_sitze.svg");
                }

                &.tueren {
                    background-image: url("../../../images/icons/icon_dtl_tueren.svg");
                }

                &.fahrzeugtyp {
                    background-image: url("../../../images/icons/icon_dtl_fahrzeugtyp.svg");
                }
            }
        }
    }
}

.product.info.detailed {
    margin-top: 20px;
}

.product-top-wrapper {
    .product-info-media-title {
        display: none;
    }

    .product-info-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .page-title-wrapper {
            display: none;
        }

        .table-wrapper {
            border-top: none;
        }
    }

    .page-title-wrapper {
        margin: 30px 0;
        @media (min-width: $screen__m) {
            margin-top: 40px;
        }
    }

    .essentials-wrapper {
        @media (max-width: $screen__l) {
            margin-top: 14px;
            padding: 0;
            order: 2;
        }

        .additional-attributes-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 26px 20px 26px 28px;
            border: none;
            margin-bottom: 14px !important;
        }

        .list-unstyled {
            margin: 0;

            li {
                margin-bottom: 2px;
            }
        }
    }

    .page-title {
        line-height: 1.3em;
        margin-top: 0;
        margin-bottom: 0 !important;
        padding: 0 !important;
        font-size: 25px;
        color: $text__color;
        font-weight: bold !important;
        text-transform: uppercase;

        .additional-headline {
            font-size: 20px;
            font-weight: normal;
            line-height: 1.4em;
            display: inline-block;
            margin-top: 5px;
        }
    }
}

.finance-block-wrapper {
    margin-top: 14px;
    @media (max-width: $screen__l) {
        order: 4;
    }
}

.product.media {
    overflow: unset;
    width: 100% !important;
    float: none;
    margin-bottom: 16px;
    position: relative;

    .loading-mask {
        position: absolute;
        background-color: transparent !important;

        .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;

            > img {
                display: none;
            }

            &::before {
                display: none;
            }
        }
    }

    @media (min-width: $screen__m) {
        overflow: hidden;
    }

    .fotorama__stage__frame {
        .fotorama__img {
            width: 100%;
            max-height: none;
        }
    }

    .fotorama__stage__shaft {
        width: 430px;
    }

    .fotorama__nav {
        width: 430px;
    }

    .fotorama__thumb__arr {
        background-color: #f7f7f7 !important;
        .fotorama__thumb--icon {
            top: 50%;
        }
    }

    .fotorama__thumb__arr--right {
        .fotorama__thumb--icon {
            padding-bottom: 21px;
        }
    }

    .fotorama__thumb__arr--left {
        .fotorama__thumb--icon {
            padding-bottom: 30px;
        }
    }

    .gallery-placeholder__image {
        position: relative;
    }
}

.product.info.detailed {
    padding: 0;

    .product.data {
        padding: 0;


        #additional {
            padding: 0;


            .data.item.title {
                padding: 10px 20px 0px;

                h2 {
                    margin: 0;
                    font-weight: bold;
                }
            }


            .container {
                max-width: 1280px;
                margin: auto;
                box-sizing: border-box;
            }

        }
    }

    .equipments {

        margin: 10px 20px;
        margin-top: 20px;

        @media (min-width: $screen__m) {
            column-count: 3;
            width: 100%;
        }

        h4 {
            font-size: 20px;
            color: $color-dovegray;
            text-decoration: none;
            line-height: 20px;
            text-transform: uppercase;

            &:first-child {
                margin-top: 0;
            }
        }

        ul {
            font-size: 14px;
            color: $color-dovegray;
            text-decoration: none;
            line-height: 24px;

            li {
                margin: 0;
                margin-bottom: 6px;
            }
        }
    }

    .equipment-box {
        position: relative;
        min-height: 200px;
        height: 200px;
        transition: height 0.3s;
        overflow: hidden;
        padding-bottom: 60px !important;
        background: #F7F6F7;

        &.in {
            max-height: initial;

            .link-cont-container {
                &::before {
                    display: none;
                }
            }
        }

        .block-content {
            margin-top: 10px;
        }

        .link-cont-container {
            position: absolute;
            width: 100%;
            height: 40px;
            left: 0;
            bottom: 0;
            background-color: #F7F6F7;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                position: absolute;
                width: 100%;
                height: 60px;
                left: 0;
                bottom: 40px;
                content: "";
                pointer-events: none;
                background: linear-gradient(0deg, #F7F6F7 0%, rgba(255, 255, 255, 0) 100%);
            }
        }

        .equipment-accordion {
            .ea-collapsible {
                //margin: 0 30px 30px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    .ea-title {
                        .icon {
                            svg {
                                transform: rotate(180deg) !important;
                            }
                        }
                    }
                }

                .ea-title {
                    padding: 10px 20px;

                    font-size: 20px;
                    color: $color-dovegray;
                    background-color: $color-white;
                    text-decoration: none;
                    text-transform: uppercase;

                    .icon {
                        position: relative;
                        max-height: 20px;
                        top: 4px;

                        svg {
                            width: 22px !important;
                            height: 22px !important;
                            fill: $color-dovegray;
                        }
                    }
                }

                .ea-content {
                    width: 100%;
                    background: #F7F6F7;
                    margin-left: 0;
                    margin-right: 0;
                    padding: 10px 0px;

                    ul {
                        //list-style: none !important;
                        //padding: 0 0 !important;
                        margin-bottom: 0;

                        li {
                            font-size: 16px;
                            color: $color-dovegray;
                            //padding-left: 1em;
                            margin-bottom: 6px;
                            background: url('../../images/icons/list-dot.svg') no-repeat left 9px;
                            //overflow: hidden;
                            text-overflow: ellipsis;
                            hyphens: auto;
                        }
                    }
                }
            }
        }
    }
}

.emissions {
    .emission-headline {
        font-weight: bold;
        font-size: 16px;
        color: $color-dovegray;
    }

    .emission__element-image {
        max-width: 375px;
        max-height: 315px;
    }

    .emission-info {
        .item.head {
            font-size: 14px;
            color: $color-dovegray;
            text-decoration: none solid rgb(105, 105, 105);
            font-weight: bold;

            margin-top: 25px;
            margin-bottom: 25px;

            @media (min-width: $screen__m) {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }

        .item.small {
            font-size: 14px;
        }

        p {
            font-size: 14px;
            color: $color-dovegray;
            text-decoration: none solid rgb(105, 105, 105);
        }

        &--hint {
            padding-top: 25px;

            @media (min-width: $screen__m) {
                padding-top: 50px;
            }
        }
    }
}

.more-information-wrapper {
    margin-top: 20px;
    margin-bottom: 50px;

    h2 {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
    }
}

.finance-info-container,
.finance-info-additional-container {
    margin: 20px 0;
}

.adjust-to-sup-with-num {
    padding-right: 7px;
}


#finance-info-detail, #finance-info-detail-additional {
    > .container, .row {
        &::before, &::after {
            display: none;
        }
    }
}

#finance-info-detail-additional, #finance-info-detail-additional-r {
    margin-top: 20px;

    .finance-info {
        margin-bottom: 20px;

        .small {
            font-size: 14px;
            color: #696969;
            text-decoration: none solid rgb(105, 105, 105);
        }
    }

    .finance-price-info {
        .small {
            font-size: 14px;
            color: #696969;
            text-decoration: none solid rgb(105, 105, 105);
        }

        .one,
        .two,
        .three,
        .four,
        .five,
        .six,
        .seven,
        .eight,
        .nine,
        .ten,
        .eleven,
        .twelve,
        .star {
            font-size: 14px;

            &::before {
                position: relative;
                top: -5px;
                left: 0px;
                font-size: 8px;
                margin-right: 3px;
            }
        }

        .one {
            &::before {
                content: "1";
            }
        }

        .two {
            &::before {
                content: "2";
            }
        }

        .three {
            &::before {
                content: "3";
            }
        }

        .four {
            &::before {
                content: "4";
            }
        }

        .five {
            &::before {
                content: "5";
            }
        }

        .six {
            &::before {
                content: "6";
            }
        }

        .seven {
            &::before {
                content: "7";
            }
        }

        .eight {
            &::before {
                content: "8";
            }
        }

        .nine {
            &::before {
                content: "9";
            }
        }

        .ten {
            &::before {
                content: "10";
            }
        }

        .eleven {
            &::before {
                content: "11";
            }
        }

        .twelve {
            &::before {
                content: "12";
            }
        }

        .star {
            &::before {
                content: "*";
            }
        }
    }
}

#finance-info-detail-additional, #finance-info-detail-additional-r {
    .row.finance-info {
        .col-xs-12 {
            padding-left: 0;
        }
    }
}

#finance-info-detail, #finance-info-detail-additional-r {
    h2 {
        font-weight: bold;
        font-size: 16px;
        color: $color-dovegray;
    }

    .shadow-container {
        height: 100%;
        padding: 0;
        color: $color-dovegray;
        min-height: 300px;

        @media (max-width: $screen__m) {
            margin-bottom: 0 !important;
        }

        @media (min-width: $screen__m) {
            width: calc(33.33333% - (18px * 2 / 3));
            margin-right: 18px;
            margin-top: 16px;
        }

        &:last-child {
            margin-right: 0;
        }

        .item.head {
            background-color: white;
            padding: 12px 24px;
            font-size: 16px;
            font-weight: normal;
        }

        .item.small {
            padding: 0 24px;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            justify-content: space-between;

            &.top {
                padding-top: 24px;
            }

            &.bottom {
                padding-bottom: 24px;
            }
        }
    }

    .finance-info {
        margin-top: 20px;
    }
    .finance-info, .finance-price-info {
        &.row {
            .col-xs-12 {
                padding-left: 0;
            }
        }
    }

    .finance-info, .finance-price-info {
        p.small {
            font-size: 14px;
        }
        .one, .two, .three, .four, .star {
            font-size: 14px;

            &::before {
                position: relative;
                top: -5px;
                left: 0;
                font-size: 8px;
                margin-right: 3px;
            }
        }

        .one {
            &::before {
                content: "1";
            }
        }

        .two {
            &::before {
                content: "2";
            }
        }

        .three {
            &::before {
                content: "3";
            }
        }

        .four {
            &::before {
                content: "4";
            }
        }

        .star {
            &::before {
                content: "*";
            }
        }
    }

    .item-obj-20 {
        margin-top: 27px;
        margin-bottom: 20px;
    }

    .item-obj-30 {
        margin-bottom: 20px;
    }

    .item-obj-40 {
        margin-bottom: 40px;
    }

    .item {
        font-size: 16px;
        font-weight: 400;
        color: #696969;
        line-height: 1.375em;

        &.head {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1.0em;
        }

        &.small {
            font-size: 14px;
        }
    }
}


.catalog-product-view .slider-frame-container {
    max-width: 1130px;

    .slider-description {
        h3 {
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
        }

        p {
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
        }

        @media (max-width: $screen-md-min) {
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 40px;
        }
        @media (min-width: $screen__xl) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .tab-content {
        padding: 0;

        @media (min-width: $screen-md-min) {
            padding: 26px 20px 16px 28px;
        }

        .products-list .list.product-items {
            margin: 0;

            @media (min-width: $screen-md-min) {
                margin: 0 10px;
            }
        }
    }
}

.price-box {
    .price-wrapper {
        float: right;
        .price {
            float: right;
        }
    }

    .price-container {
        margin-right: 6px;
        .delivery-cost-placeholder {
            float: right;
        }

        &.contains-superscript-mark {
            margin-right: 0 !important;
        }
    }
}

.hidden-element {
    display: none;
}
