.show-on-product-card {
    display: none;
}
.item.product.product-item {
    .show-on-product-card {
        display: block !important;
    }
}
.products.list .product-item-details-price .price-container .price-label {
    display: none !important;
}