$button-red: #D51224;
$gray-button-color: #696969;

.button-red-new {
    font-size: 16px !important;
    background: $button-red !important;
    border-width: 0 !important;
    color: white !important;
    padding: 18px !important;
    width: 100%;
    text-align: center !important;
    margin-bottom: 0 !important;

    @media (min-width: $screen-md-min) {
        font-size: 18px !important;
    }

    &:hover,
    &:active,
    &:visited,
    &:focus {
        background: $button-red !important;
    }
}
.button-link {
    cursor: pointer;
    color: $gray-button-color;
    text-decoration: underline;

    &:hover,
    &:active,
    &:visited,
    &:focus {
        color: $gray-button-color;
        text-decoration: underline;
    }
}
.share-offer-link,
.expose-link,
.button-link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    svg {
        margin-right: 4px;
        min-height: 14px;
        min-width: 14px;
    }
}