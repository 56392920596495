$nav-sections-mobile-height: 59px;
$nav-sections-desktop-height: 59px;
@media (min-width: $screen__m) {
    ul.offcanvas-layer {

        top: 73px;
        width: 100%;

        @media (min-width: 768px) {
            max-width: 1040px;
        }

        @media (max-width: 1440px) {
            max-width: 1240px;
        }

        left: 50%;
        transform: translate(-50%, 0) !important;
        height: unset;
        //min-height: 300px;
        position: fixed;
        background: white;
        border: 1px solid #ccc;
        bottom: unset;

        display: flex !important;
        justify-content: space-between;
        height: auto !important;
        overflow: visible;

        button.offcanvas-toggle {
            display: none;
        }

        & > ul.nav.navbar-nav {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;

            > li {
                flex: 0;
                float: none;
                margin-bottom: 0;

                &.open {
                    a {
                        color: $color-red;
                    }

                }

                a {
                    white-space: nowrap;
                    color: $text__color;
                }

                a:hover {
                    color: $color-red;
                }

                > a {
                    display: flex;
                    height: $nav-sections-desktop-height;
                    align-items: center;

                    @media (min-width: $screen__m) {
                        height: $nav-sections-desktop-height;
                    }
                }

                > ul {
                    top: 60px;

                    // for the borders of the parent:
                    margin-left: -1px;
                    margin-right: -1px;
                }

                @media (max-width: 930px) {
                    a {
                        padding-left: 7px;
                        padding-right: 7px;
                    }
                }

                @media (max-width: 810px) {
                    a {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }


            }
        }

        ul.dropdown-menu {
            li {
                padding: 2px 0;

                a {
                    white-space: normal;
                }
            }
        }

        ul.dropdown-menu {
            top: auto;
        }

    }
}
.go-back-link {
    margin-left: -7px;

    .go-back-link-arrow {
        svg {
            margin-top: -1px;
            width: 23px !important;
            height: 23px !important;
        }
    }
}


.page-header {
    .menu-icons {
        .icon {
            margin: 3px 7px 0 0;
        }

        .mobility-partner {
            width: 250px;
        }
    }
}

.header-panel-top {
    .hidden-mobile {
        &.header-welcome-text {
            p {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 2px;
            }
        }
    }
}

@media (max-width: ($screen__m - 1)) {
    .page-header {
        .panel.wrapper {
            .header-panel-top {
                .navbar-toggle.main-menu, .navbar-toggle.contact {
                    display: block;
                    background-color: transparent !important;
                    margin-right: 0;
                    overflow: visible;
                    height: auto !important;
                    padding: 0;
                    flex: none;
                }
            }
        }
    }

    .offcanvas-menu {
        .navbar-nav, .actions {
            max-width: 320px;
        }

        > ul.navbar-nav.yamm {
            margin: 0 auto;
            max-width: 320px;
        }

        .yamm {
            .dropdown-menu {
                .yamm-content {
                    background-color: white;
                    padding: 0 25px;

                    .subpages {
                        li {
                            padding-left: 25px;
                        }
                    }
                }
            }
        }

        .actions {
            margin: 15px auto;
            max-width: 350px;
            padding: 0 15px;

            a.button {
                display: block;
                color: $color-white;
                font-weight: bold;
            }
        }


        #offcanvas-company-menu {
            .navbar-nav, .actions {
                max-width: 350px;
            }

            .actions {
                padding: 0 30px;
            }
        }


        &.offcanvas-layer {
            ul.navbar-nav {
                margin-top: 50px;
                padding-left: 30px;


                > li {
                    float: none;
                    text-transform: uppercase;
                }

                > li a {
                    color: $text__color;
                }

                > li.dropdown.has-sub {
                    text-transform: none;

                    &:first-child {
                        > a {
                            @media (min-width: $screen__m) {
                                font-size: 18px;
                            }
                        }
                    }

                    > a {
                        position: relative;
                        font-size: 16px;
                        text-transform: uppercase;
                        padding: 15px 45px 15px 15px;

                        &::after {
                            transform: rotate(-90deg);
                            content: '';
                            width: 25px !important;
                            height: 25px !important;
                            position: absolute;
                            right: 15px;
                            top: 14px;
                        }
                    }
                }

                .yamm-link {
                    padding: 15px 0;

                    ul.subpages li {
                        &::after {
                            top: 15px;
                        }
                    }
                }
            }

            li {
                & > a {
                    padding: 15px 45px 15px 15px;
                    background: transparent;
                }
            }
        }

    }
}


.container.subheader-container {
    display: flex;
    align-items: center;

    .breadcrumbs {
        flex: 1;
    }

    .breadcrumbs,
    .subheader-search {
        margin-top: 10px;
    }

    // @todo is there better way to do this?
    .breadcrumbs + .subheader-search {
        display: block !important;
    }

    .subheader-search {
        display: none;

        @media (min-width: $screen__xl) {
            margin-right: -20px;
        }

        a {
            text-decoration: none;
            transition: all .15s ease;
            color: $text__color;
            font-size: 16px;

            & > div {

                display: inline-flex;
                align-items: center;
            }

            svg {
                width: 31px !important;
                height: 31px !important;
                margin-right: 4px;
            }

            &:hover {
                span {
                    color: $text__color;
                }

                svg {
                    fill: $color-red;
                }
            }
        }
    }
}

.header-welcome-text {
    white-space: nowrap;
    font-size: 14px;
    line-height:18px;

    a {
        color: white !important;
    }
}


.quick-links  {
    max-width: 360px;
    margin: 0px auto;


    .mobile-quick-links.yamm,
    ul.main-quick-links.yamm {
        padding-top: 5px;
    }

    .mobile-quick-links.yamm > ul,
    ul.main-quick-links.yamm {
        list-style: none;
        display: flex;
        justify-content: center;
    }



    span {
        display: inline-block;
        width: 100%;
        text-align: center;

        .svg {
            pointer-events: none;
            width: 24px !important;
            height: 24px !important;
            fill: #696969;
        }

        &.item-text {
            font-size: 9px;
            vertical-align: text-top;
            text-transform: uppercase;
            color: #696969;
        }
    }
}

@media (max-width: ($screen__m - 1)) {

    .cms-index-defaultindex,
    .searchfilter-detail-index {
        padding-top: 116px;
    }


    .catalog-category-view {
        padding-top: 132px;
    }

}
