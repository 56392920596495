$screen-sm-min-2: 375px;
$backcolor: #f7f7f7;
$color-normal: #b5b5b5;
$dove-gray: #696969;
$color-active: $dove-gray;
$border-color: #94949421;
$red: #E51D2A;
$button-red: #D51224;

.finance-clear {
    clear: both;
}
.finance-tab-bottom {
    padding: 0 20px 26px 28px;

    @media (min-width: $screen-md-min) {
        display: flex;
    }
    .share-offer-link,
    .expose-link,
    .autouncle-link {
        svg {
            @media (max-width: $screen-md-min) {
                min-height: 16px;
                min-width: 16px;
            }
        }
    }

    .share-offer-link,
    .expose-link, {
        .link-text {
            display: block;
            @media (max-width: $screen__xs) {
                font-size: 8px;
            }
        }
    }

    .autouncle-link {
        display: flex;
        span {
            display: block;
            @media (max-width: $screen__xs) {
                font-size: 8px;
            }
        }
    }
}
.tab-content {
    padding: 26px 20px 26px 28px;
    @media (min-width: $screen-phone) {
        padding: 26px 20px 16px 28px;
    }
}
.product-social-links {
    display: none;
}
.finance-area {
    padding: 0 0 0 0 !important;
    position: relative;

    .product-add-form {
        .testdrive {
            margin-bottom: 0 !important;
        }
    }

    .finance-config-area,
    .barpreis-area {
        min-height: 315px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .finance-nav {
        font-family: "Open Sans";
        font-weight: 600;
        font-style: normal;
        font-size: 20px;

        color: #696969;
        text-decoration: none solid rgb(105, 105, 105);
        text-transform: uppercase;
        text-align: center;

        .row {
            line-height: 10px;
        }

        ul, a {
            font-size: 16px;
        }

        ul {
            display: inline-block;
            padding: 3px 0px;
        }

        a {
            padding: 0 15px;
        }

        @media (min-width: $screen-sm-min-2) {
            ul, a {
                font-size: 20px;
            }

            a {
                padding: 10px 10px;
            }
        }

        .tabs-background {
            background: white;
            padding: 0;
            border: 1px solid $border-color;
            height: 32px;
        }

        .finance-nav-tabs {
            @media (min-width: $screen-md-min) {
                float: right !important;
            }
        }

        .nav-tabs {
            border: 0;
            background-color: transparent;
            width: 100%;
            padding: 0;


            // disable scroll toolbars
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }


            .nav-item {
                width: 33.33%;
                height: 32px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;

                    @media (min-width: $screen-md-min) {
                        font-size: 16px;
                    }
                }

                &.active {
                    a {
                        font-size: 14px;
                        @media (min-width: $screen-md-min) {
                            font-size: 16px;
                        }
                    }
                }

                &.disabled {
                    a {
                        &:hover {
                            background: white;
                            color: $color-normal;
                            border-color: $border-color;
                        }
                    }
                }

                a {
                    color: $color-normal;
                    border-radius: 0 !important;
                    padding: 0 !important;
                    height: 100%;
                    margin-right: -1px;

                    &:hover {
                        color: $color-active;
                        background: $backcolor;
                        border-color: $backcolor;
                    }
                }

                &.active {
                    a {
                        border: 1px solid $border-color;
                        border-top-color: $backcolor !important;
                        border-bottom-color: $backcolor !important;
                        background-color: $backcolor;
                        color: $color-active;
                        font-weight: bold;
                    }

                }
            }
        }

    }

    .fin-item-button {
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            margin-top: 40px;
            margin-bottom: 40px;
            flex-direction: row;
        }

        .button-area {
            @media (min-width: $screen-sm-min) {
                min-width: 340px;
                max-width: 340px;
            }
        }

        .result-price-area {
            width: 100%;
            margin-top: auto;

            @media (min-width: $screen-sm-min) {
                margin-bottom: -20px;
            }
        }

        .priceline {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            text-align: right;
            margin-top: 20px;

            .price {
                font-size: 25px;
            }
            .infotext {
                font-size: 12px;
                text-transform: unset;
            }
        }

    }

    .select-container {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        label {
            font-size: 14px;
            margin: 0;
            min-width: 120px;
            font-weight: normal;

            @media (min-width: $screen-sm-min) {
                min-width: 160px;
            }
        }

        select {
            font-size: 12px;
            height: 36px !important;
            margin: 0;
            text-align: right;
        }
    }

    .full-width-container {
        background-color: $backcolor;

        .additional {
            padding: 40px 20px 20px 20px;
        }
    }
}

.finance-ende {
    padding-top: 20px;
    padding-bottom: 20px;
    //background-color: #0a6c9f;
    background-color: rebeccapurple;
    color: white;
}

.dkTextClass {
    color: red;
}

p.finance-message {
    margin-top: 1em;
    text-align: center;
}

.product-social-links {
    margin-top: 16px;
}

.product-info-price {
    margin-top: 30px;

    @media (min-width: $screen-md-min) {
        margin-top: 40px;
    }
}

.offer-type {
    margin-bottom: 35px;
}

.price-box {
    display: flex;
    flex-direction: column;

    .price-advantage {
        color: $red;
        font-weight: bold;
    }

    .price-container,
    .price-advantage {
        .price-label {
            min-width: 160px;
            display: inline-block !important;
        }
    }

    .special-price,
    .normal-price {
        display: flex;
        flex-direction: row-reverse;
        font-weight: bold;
        margin-top: 10px;

        .price-container {
            display: flex;
            flex-direction: column;

            .price-wrapper {
                text-align: right;

                .price {
                    font-size: 25px;
                    font-weight: bold;
                }
            }

            .price-label {
                min-width: initial !important;
                font-size: 12px;
                text-transform: uppercase;
                margin-bottom: 12px;
                text-align: right;
            }
        }
    }

    .plus-tax-container{
        margin-top: 20px;
    }
}

.share-offer-link {
    position: relative;

    .share-tooltip-container {
        position: absolute;
        bottom: 30px;
        border: 1px solid $dove-gray;
        background: white;
        padding: 10px;
        z-index: 5;
        display: flex;
        width: 100px;
        justify-content: space-between;

        &::after {
            content: ' ';
            width: 10px;
            height: 10px;
            background: white;
            z-index: 6;
            position: absolute;
            bottom: -6px;
            left: 10%;
            border: 1px solid $dove-gray;
            border-top-width: 0;
            border-right-width: 0;
            transform: rotateZ(-45deg);
        }
    }
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltip-text {
    position: absolute;
    width: 100%;
    left: 50%;
    bottom: 150%;
    padding: 5px 5px;
    margin-left: -50%;
    visibility: visible;
    z-index: 6;

    border: 1px solid $dove-gray;
    background: white;
    text-align: center;
    text-transform: none;

    font-size: 12px;
}

.tooltip .tooltip-text::after {
    content: ' ';
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -6px;
    left: 50%;
    margin-left: -5px;
    z-index: 6;
    background: white;
    border: 1px solid $dove-gray;
    border-top-width: 0;
    border-right-width: 0;
    transform: rotateZ(-45deg);
}

.icon {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    color: $dove-gray;
    width: 18px;
    height: 18px;
    display: block;
}
.facebook-icon {
    background-image: url('../../../images/icons/social/facebook.svg');
}
.email-icon {
    background-image: url('../../../images/icons/social/mail.svg');
}
.whatsapp-icon {
    background-image: url('../../../images/icons/social/whatsapp.svg');
}
